import Swal from "sweetalert2";
import { Turbo } from "@hotwired/turbo-rails";

const newConfirmMethod = async (message, formElement) => {
  const category = formElement.dataset.category;
  const type = formElement.dataset.type;
  const description = formElement.dataset.description;
  const cancelButtonText = formElement.dataset.cancelButtonText;
  const confirmButtonText = formElement.dataset.confirmButtonText;
  const result = await Swal.fire(
    targetConfirmConfig(
      message,
      description,
      category,
      type,
      cancelButtonText,
      confirmButtonText,
    ),
  );

  return new Promise((resolve, reject) => {
    if (result.isConfirmed) {
      resolve(true);
    }
  });
};

// NOTE: 共通化するとカスタマイズが困難になるので、 category で出しわけできるようにする
const targetConfirmConfig = (
  message,
  description,
  category,
  type,
  cancelButtonText,
  confirmButtonText,
) => {
  switch (category) {
    case "changeStatus": {
      const confirmButtonColor = type === "published" ? "#d33" : "#0061b6";

      return {
        title: `${message}にしますか？`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: "text-gray-900",
        cancelButtonText: "キャンセル",
        confirmButtonText: `${message}する`,
        reverseButtons: true,
      };
    }

    case "useFeature": {
      const confirmButtonColor = type === "use" ? "#d33" : "#0061b6";

      return {
        title: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: "text-gray-900",
        cancelButtonText: "キャンセル",
        confirmButtonText: "実行する",
        reverseButtons: true,
      };
    }

    case "sendScout": {
      return {
        title: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0061b6",
        cancelButtonColor: "text-gray-900",
        cancelButtonText: "戻る",
        confirmButtonText: "送信する",
        reverseButtons: true,
      };
    }

    default:
      return {
        title: message,
        icon: "warning",
        text: description,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "text-gray-900",
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        reverseButtons: true,
      };
  }
};

Turbo.setConfirmMethod(newConfirmMethod);
