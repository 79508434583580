import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "panel"];

  // connect() {
  // }

  open() {
    // TODOアニメーション
    this.element.classList.remove("hidden");
  }

  close() {
    // TODOアニメーション
    this.element.classList.add("hidden");
  }

  hundleBack(event) {
    if (
      event.target.classList.contains("outer-frame") &&
      this.element.dataset.modal != "true"
    ) {
      this.close();
    }
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }

  never_show() {
    document.cookie = this.element.dataset.cookieKey + "=true; max-age=86400";
    this.dialog.close();
  }
}
