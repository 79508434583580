import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btn", "bg", "menu"];

  open() {
    this.openWindowTarget.querySelector(".navi").classList.toggle("active");
  }

  toggle() {
    if (this.btnTarget.classList.contains("active")) {
      this.btnTarget.classList.remove("active");
      this.bgTarget.classList.remove("active");
      this.menuTarget.classList.remove("active");
    } else {
      this.btnTarget.classList.add("active");
      this.bgTarget.classList.add("active");
      this.menuTarget.classList.add("active");
    }
  }
}
