import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      modules: [Navigation, Pagination, Scrollbar],
      loop: this.element.dataset.loop == "false" ? false : true,
      freeMode: true,
      slidesPerView: this.element.dataset.smPer || 1,
      spaceBetween: 10,
      pagination: {
        enabled: false,
        el: ".swiper-pagination",
      },
      navigation: {
        enabled: false,
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      scrollbar: {
        enabled: true,
        el: ".swiper-scrollbar",
      },
      breakpoints: {
        640: {
          slidesPerView: this.element.dataset.smPer || 1,
          spaceBetween: 20,
          scrollbar: {
            enabled: false,
            el: ".swiper-scrollbar",
          },
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: this.element.dataset.lgPer || 3,
          spaceBetween: 10,
          navigation: {
            enabled: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      },
    });
  }
}
